'use client'

import { useState, useRef } from 'react'
import dynamic from 'next/dynamic'
import { Box, Button, Grid2 as Grid, Typography } from '@mui/material'

// Types
import type { VideoProps } from '@/types/components/atoms/videos'

// Components
const PlayIconAtom = dynamic(() => import('@/components/atoms/icons/play'))

const VideoAtom = (props: VideoProps) => {
	// Props
	const { src, width, height, title, description } = props
	const [isPlaying, setIsPlaying] = useState(false)
	const videoRef = useRef<HTMLVideoElement>(null)

	return (
		<Grid size={12} position="relative">
			<video ref={videoRef} onClick={() => setIsPlaying(!isPlaying)} controls={isPlaying} width={width} height={height}>
				<source src={src} type="video/mp4" />
				Your browser does not support HTML video.
			</video>
			{!isPlaying && (
				<Button
					onClick={() => {
						setIsPlaying(true)
						if (videoRef.current) {
							videoRef.current.play()
						}
					}}
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						minWidth: { xs: 46, md: 96 },
						width: { xs: 46, md: 96 },
						height: { xs: 46, md: 96 },
						transform: 'translate(-50%, -50%)',
						bgcolor: 'common.white',
						color: 'text.secondary',
						borderRadius: '50%',
						'&:hover': { bgcolor: 'common.white' },
						'& .MuiSvgIcon-root': { fontSize: { xs: 20, md: 36 }, ml: { xs: 0.75, md: 1.5 } }
					}}
				>
					<PlayIconAtom />
				</Button>
			)}

			<Box
				width={1}
				height="50%"
				sx={{
					position: 'absolute',
					top: '50%',
					left: 0,
					background: 'linear-gradient(180deg, rgba(217, 217, 217, 0.00) -1.06%, #6F5D80 53.07%, #290C46 88.41%)',
					mixBlendMode: 'multiply',
					opacity: isPlaying ? 0 : 1,
					transition: 'opacity 0.5s ease'
				}}
			></Box>

			{!isPlaying && (
				<Box
					sx={{
						position: 'absolute',
						bottom: { xs: '-15px', md: '8%' },
						left: { xs: '35%', sm: '30%', md: '25%' },
						transform: 'translate(-50%, -50%)'
					}}
				>
					<Typography variant="body1" fontSize={{ xs: 32, md: 48 }} fontWeight={700} color="common.white">
						{title}
					</Typography>

					<Typography variant="body1" fontSize={{ xs: 16, md: 24 }} color="common.white">
						{description}
					</Typography>
				</Box>
			)}
		</Grid>
	)
}

export default VideoAtom
